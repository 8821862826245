import * as React from "react"



// markup
const NotFoundPage = () => {
  return (
    <div>Page Not Found</div>
  )
}

export default NotFoundPage
